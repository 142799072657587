import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import auth0 from './auth0';

const httpLink = createHttpLink({
	uri: '/graphql',
});

const authLink = setContext(async (_, { headers }) => {
	const accessToken = await auth0.getIdTokenClaims();

	return {
		headers: {
			...headers,
			authorization: accessToken ? `Bearer ${accessToken.__raw}` : '',
		},
	};
});

const apolloClient = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache({
		addTypename: false,
		typePolicies: {
			Query: {
				fields: {
					paginateNominations: {
						keyArgs: [],
						merge(existing = { count: 0, items: [] }, incoming = { count: 0, items: [] }, { args: { skip = 0 } }) {
							// If skip is 0, we are fetching for the first time or re-fetching!
							// So we need to just return incoming
							if (skip === 0) {
								return { count: incoming.count, items: [...incoming.items] };
							}

							// Otherwise, if skip is not 0, we're trying to fetch more result
							// so existing needs to be merged with incoming
							return { count: incoming.count, items: [...existing.items, ...incoming.items] };
						},
					},
				},
			},
		},
	}),
});

export default apolloClient;

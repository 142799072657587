import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
	text-align: center;
	min-height: ${p => (p.fullheight === 'true' ? '100vh' : '0')};
	padding-top: 250px;
	${p =>
		p.theme.media.mediumDown(css`
			min-height: auto;
			padding-top: 150px;
		`)};
`;

const DotsWrapper = styled.svg`
	width: 100%;
	display: block;
	max-width: 75px;
	margin: 0 auto;
	circle {
		fill: ${p => (p.color === 'green' ? p.theme.colors.green700 : p.color === 'black' ? p.theme.colors.black : p.theme.colors.blue600)};
	}
`;

export default function Loading({ color = '', fullHeight, ...props }) {
	return (
		<Wrapper fullheight={fullHeight?.toString()} {...props}>
			{/** Animated loading dots */}
			<DotsWrapper x="0px" y="0px" viewBox="0 0 52 20" enableBackground="new 0 0 0 0" color={color}>
				<circle stroke="none" cx="6" cy="10" r="6">
					<animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
				</circle>

				<circle stroke="none" cx="26" cy="10" r="6">
					<animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
				</circle>

				<circle stroke="none" cx="46" cy="10" r="6">
					<animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
				</circle>
			</DotsWrapper>
			{props?.text && <p style={{ marginTop: '20px', fontStyle: 'italic' }}>{props.text}</p>}
		</Wrapper>
	);
}

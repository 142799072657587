import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Authorized, UnAuthorized } from 'config/auth0';
import Loading from 'pages/generic/Loading';

const Login = lazy(() => import('pages/generic/Login'));
const Home = lazy(() => import('pages/Home'));

// Crisis Messages
const CrisisMessagesArchive = lazy(() => import('pages/CrisisMessages/Archive'));
const CrisisMessagesAddNew = lazy(() => import('pages/CrisisMessages/AddNew'));
const CrisisMessagesEdit = lazy(() => import('pages/CrisisMessages/Edit'));

// Contests (used for both Laget- and Nærmiljøet Mitt)
const Nominations = lazy(() => import('pages/Contests/Nominations'));
const Nomination = lazy(() => import('pages/Contests/Nomination'));
const Settings = lazy(() => import('pages/Contests/Settings'));
const VotesSearch = lazy(() => import('pages/Contests/VotesSearch'));
const EmailList = lazy(() => import('pages/Contests/EmailList'));

// Laget Mitt
const FrontPageMyTeam = lazy(() => import('pages/MyTeam/FrontPage'));
const ZoneList = lazy(() => import('pages/MyTeam/Zones/ZoneList'));
const AddTeamZone = lazy(() => import('pages/MyTeam/Zones/AddTeamZone'));
const EditTeamZone = lazy(() => import('pages/MyTeam/Zones/EditTeamZone'));
const ZoneVotes = lazy(() => import('pages/MyTeam/Zones/ZoneVotes'));
const MyTeamExport = lazy(() => import('pages/MyTeam/Export'));
const MyTeamStats = lazy(() => import('pages/MyTeam/Stats'));

// Data Export
const Export = lazy(() => import('pages/Export/Export'));
// const PublicExport = lazy(() => import('pages/Export/PublicExport'));
const DataManager = lazy(() => import('pages/DataManager'));

// 404
const NotFound = lazy(() => import('pages/NotFound'));

function App() {
	return (
		<BrowserRouter>
			<Suspense fallback={<Loading fullpage="true" />}>
				<Authorized>
					<Routes>
						<Route exact path="/" element={<Home />} />
						<Route exact path="/viktige-meldinger" element={<CrisisMessagesArchive />} />
						<Route exact path="/ny-melding" element={<CrisisMessagesAddNew />} />
						<Route exact path="/viktige-meldinger/endre-melding/:messageId" element={<CrisisMessagesEdit />} />

						<Route exact path="/eksport" element={<Export />} />
						<Route exact path="/data-behandling" element={<DataManager />} />

						<Route exact path="/laget-mitt" element={<FrontPageMyTeam contestType="Laget Mitt" />} />
						<Route exact path="/laget-mitt/stemmer" element={<ZoneVotes />} />
						<Route exact path="/laget-mitt/sok-etter-stemme" element={<VotesSearch contestType="Laget Mitt" />} />
						<Route exact path="/laget-mitt/soner-og-nominasjoner" element={<ZoneList contestType="Laget Mitt" />} />
						<Route exact path="/laget-mitt/soner-og-nominasjoner/ny-sone/" element={<AddTeamZone />} />
						<Route exact path="/laget-mitt/soner-og-nominasjoner/rediger/:zone/" element={<EditTeamZone contestType="Laget Mitt" />} />
						<Route exact path="/laget-mitt/soner-og-nominasjoner/epostliste/:zone/" element={<EmailList contestType="Laget Mitt" />} />
						<Route exact path="/laget-mitt/soner-og-nominasjoner/:zone" element={<Nominations contestType="Laget Mitt" />} />
						<Route exact path="/laget-mitt/soner-og-nominasjoner/:zone/:id" element={<Nomination contestType="Laget Mitt" />} />
						<Route exact path="/laget-mitt/innstillinger" element={<Settings contestType="Laget Mitt" />} />
						<Route exact path="/laget-mitt/eksport" element={<MyTeamExport contestType="Laget Mitt" />} />
						<Route exact path="/laget-mitt/statistikk" element={<MyTeamStats />} />

						<Route path="*" element={<NotFound />} />
					</Routes>
				</Authorized>

				<UnAuthorized>
					<Routes>
						<Route exact path="/" element={<Login />} />
						{/* <Route exact path="/kti-eksport" element={<PublicExport />} /> */}
						<Route path="*" element={<NotFound />} />
					</Routes>
				</UnAuthorized>
			</Suspense>
		</BrowserRouter>
	);
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StyleSheetManager } from 'styled-components';

import GlobalStyling from 'components/GlobalStyling';
import THEME from './theme';
import App from './App';
import { Auth0Provider } from 'config/auth0';
import apolloClient from 'config/graphql';

const propBlacklist = ['svgWidth', 'fullWidth', 'active'];

ReactDOM.createRoot(document.getElementById('app')).render(
	<Auth0Provider>
		<ApolloProvider client={apolloClient}>
			<StyleSheetManager shouldForwardProp={p => !propBlacklist.includes(p)}>
				<ThemeProvider theme={THEME}>
					<GlobalStyling />
					<App />
				</ThemeProvider>
			</StyleSheetManager>
		</ApolloProvider>
	</Auth0Provider>
);
